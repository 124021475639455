<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.withdrawRecords") }}</li>
      </ul>
    </div>
    <div class="Account_fixed">
      <ul>
        <li class="li_text" v-if="userDetail">
          {{ t("profile.label.walletBalance") }}:
          <b>{{ formatMoney(userDetail.amount) }}</b>
        </li>
      </ul>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll"
    >
      <div class="main main_page">
        <div class="Account_list">
          <div class="no_data" v-if="!listItems || listItems.length == 0">
            <p>{{ t("common.message.noRecords") }}</p>
          </div>
          <div class="bata" v-else>
            <ol :key="index" v-for="(item, index) in listItems">
              <a @click="showRemark(+item.status,item.remark)">
                <h2>
                  {{ t("profile.label.orderNumber") }}:
                  <b>{{ item.orderNo }}</b>
                </h2>
                <h3
                  :class="
                    +item.status === 2
                      ? 'c-green'
                      : +item.status === 7 || +item.status === 4
                      ? 'c-999'
                      : 'c-orange'
                  "
                >
                  {{ formatMoneySign(-item.money - item.fee) }}
                </h3>
                <li>
                  <span>{{ formatShortDateTime(item.createDate) }}</span>
                </li>
                <li
                  :class="
                    +item.status === 2
                      ? 'c-green'
                      : +item.status === 7 || +item.status === 4
                      ? 'c-999'
                      : 'c-orange'
                  "
                >
                  {{ t(`common.processStatus.${item.status}`) }}
                </li>
                <h2 v-if="sysDataDictionaryWbObj?.TKBZ?.dataOnOff && (+item.status === 7 || +item.status === 4)" class="c-red">{{t('extra.remark')}}</h2>
              </a>
            </ol>
          </div>
        </div>
      </div>
    </BetterScroll>

    <AModal
      v-model:visible="visible"
      centered
      :footer="null"
      :title="t('extra.remark')"
      @ok="visible = false"
      wrapClassName="Sys_Popup"
      width="85%"
      :zIndex="9999"
    >
      <div class="Sys_Popup_wrap">
        <div class="box text-pre-line">
          {{ remarkObj }}
        </div>
      </div>
    </AModal>
  </div>
</template>
<script>
import { ref, watch, onMounted, reactive } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import api from "@/api";
import { userDetail } from "@/hooks/userDetail";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import {
  formatMoney,
  formatMoneySign,
  formatPercentage,
  formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
// import common from '@/lang/en/common.js';
export default {
  components: { BetterScroll },
  setup() {
    const { t } = useI18n();
    const scrollRef = ref(null);
    const isStopReq = ref(false);
    const listItems = reactive([]);
    const currentPage = ref(0);
    const visible = ref(false);
    const remarkObj = ref('');
    
    const alreadyReq = ref(false);
    const fetchMorePage = () => {
      if (isStopReq.value) {
        return;
      }
      api
        .proxyOrder({
          current: currentPage.value + 1,
          size: 10,
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value + 1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };

    onMounted(() => {
      fetchMorePage();
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });

    function showRemark(status, remark){
      remarkObj.value = ''
      if(!sysDataDictionaryWbObj.value?.TKBZ?.dataOnOff)return
      if(+status === 7 || +status === 4){
        visible.value = true
        remarkObj.value = remark
      }
    }

    return {
      t,
      scrollRef,
      formatMoney,
      formatMoneySign,
      formatPercentage,
      formatShortDateTime,
      fetchMorePage,
      listItems,
      alreadyReq,
      userDetail,
      visible,
      remarkObj,
      sysDataDictionaryWbObj,
      showRemark
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0px;
}
.c-red{
  text-decoration:underline;
}
.Account_list ol li:nth-child(3){
  width: 40%;
}
.Account_list ol li:nth-child(4) {
  width:60%;
}
</style>
